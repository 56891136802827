import { types as at, errorAction, types } from "@authzsvc/api-lib";
import { notification } from "antd";
import React, { useCallback, useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { Header, Icon, Segment, Button } from "semantic-ui-react";
import {
  addGroupMemberGroups,
  addGroupMemberIdentities,
  deleteGroupMemberGroups,
  deleteGroupMemberIdentities,
  getGroupMemberGroupsWithComments,
  getGroupMemberIdentitiesWithComments,
} from "../../actions/api";
import { getDefaultGroupMembersTableColumns } from "../../constants";
import AddMemberSearchModal from "../common/AddMemberSearchModal";

import GroupMembersView from "./members/GroupMembersView";
import CSVModal from "./members/CSVModal";
import { showErrorNotification } from "../../util";
import EGroupWarning from "./EGroupWarning";

const GroupMembers = ({
  // props
  match,
  selectedGroup,
  refreshMembers,
}) => {
  const dispatch = useDispatch();

  const [blocked, setBlocked] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const searchValueRef = useRef(searchValue);
  const [filter, setFilter] = useState("contains");
  const filterRef = useRef(filter);
  const [members, setMembers] = useState([]);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [isImportModalOpen, setImportModalOpen] = useState(false);
  const [isDownload, setIsDownload] = useState(false);
  const [isInitialized, setIsInitialized] = useState(false);
  const [isLimitReached, setIsLimitReached] = useState(false);
  const [isAccessChecked, setIsAccessChecked] = useState(false);

  useEffect(() => {
    searchValueRef.current = searchValue;
  }, [searchValue]);

  useEffect(() => {
    filterRef.current = filter;
  }, [filter]);

  const getMembers = useCallback(
    async (
      searchText = searchValueRef.current,
      filterValue = filterRef.current
    ) => {
      setLoading(true);

      const createQueryFilter = (field) => {
        return searchText
          ? { filter: [`${field}:${filterValue}:${searchText}`] }
          : {};
      };

      const [identitiesByUpn, identitiesByDisplayName, groups] =
        await Promise.all([
          dispatch(
            getGroupMemberIdentitiesWithComments(
              match.params.id,
              createQueryFilter("upn")
            )
          ),
          searchText
            ? dispatch(
                getGroupMemberIdentitiesWithComments(
                  match.params.id,
                  createQueryFilter("displayName")
                )
              )
            : { payload: { data: [] } },
          dispatch(
            getGroupMemberGroupsWithComments(
              match.params.id,
              createQueryFilter("displayName")
            )
          ),
        ]);

      const isErrorAction =
        identitiesByUpn?.type ===
          errorAction(types.GET_GROUP_MEMBER_IDENTITIES_WITH_COMMENTS) ||
        (searchText &&
          identitiesByDisplayName?.type ===
            errorAction(types.GET_GROUP_MEMBER_IDENTITIES_WITH_COMMENTS)) ||
        groups?.type ===
          errorAction(types.GET_GROUP_MEMBER_GROUPS_WITH_COMMENTS);

      if (isErrorAction) {
        if (
          identitiesByUpn?.payload?.data?.status === 403 ||
          (searchText &&
            identitiesByDisplayName?.payload?.data?.status === 403) ||
          groups?.payload?.data?.status === 403
        ) {
          setBlocked(true);
        } else {
          const title =
            identitiesByUpn?.payload?.title ||
            (searchText && identitiesByDisplayName?.payload?.title) ||
            groups?.payload?.title ||
            "An error occurred fetching the members";
          const description =
            identitiesByUpn?.payload?.data?.message ||
            (searchText && identitiesByDisplayName?.payload?.data?.message) ||
            groups?.payload?.data?.message ||
            "Please try again later";

          notification.error({
            message: title,
            description: description,
          });
        }
      } else {
        const totalMembershipCount =
          (identitiesByUpn?.payload?.pagination?.total || 0) +
          (groups?.payload?.pagination?.total || 0);
        const identitiesByUpnData = identitiesByUpn?.payload?.data || [];
        const identitiesByDisplayNameData =
          identitiesByDisplayName?.payload?.data || [];
        const groupsData = groups?.payload?.data || [];

        const mergedIdentities = [
          ...identitiesByUpnData,
          ...identitiesByDisplayNameData,
        ];

        const uniqueIdentitiesMap = new Map();
        mergedIdentities.forEach((identity) => {
          uniqueIdentitiesMap.set(identity.memberIdentity.id, identity);
        });

        const uniqueIdentities = Array.from(uniqueIdentitiesMap.values());

        setMembers([...uniqueIdentities, ...groupsData]);
        setTotal(totalMembershipCount);
        setIsLimitReached(
          identitiesByUpnData.length >= 1000 ||
            identitiesByDisplayNameData.length >= 1000 ||
            groupsData.length >= 1000
        );
      }

      setIsInitialized(true);
      setLoading(false);
      setIsAccessChecked(true);
    },
    [dispatch, match.params.id]
  );

  useEffect(() => {
    getMembers();
  }, [getMembers, refreshMembers]);

  const handleSearch = useCallback(
    (searchText, filterValue) => {
      if (filterValue !== filterRef.current) {
        setFilter(filterValue);
      }
      getMembers(searchText, filterValue);
    },
    [getMembers]
  );

  const handleErrorActions = (result, actionType) => {
    if (result.type === errorAction(actionType)) {
      showErrorNotification(result.payload.title, result.payload.data.message);

      return false;
    }

    return true;
  };

  const onDeleteMembers = async (selectedRows) => {
    const { id: groupId } = match.params;

    const deleteActions = [
      {
        type: "identity",
        action: deleteGroupMemberIdentities,
        actionType: types.DELETE_GROUP_MEMBER_IDENTITIES,
      },
      {
        type: "group",
        action: deleteGroupMemberGroups,
        actionType: types.DELETE_GROUP_MEMBER_GROUPS,
      },
    ];

    for (const { type, action, actionType } of deleteActions) {
      const ids = selectedRows
        .filter((row) => row.type === type)
        .map((row) => row.id);
      if (ids.length > 0) {
        const result = await dispatch(action(groupId, ids));
        if (!handleErrorActions(result, actionType)) {
          return;
        }
      }
    }

    await getMembers();
  };

  const handleAddMember = async (newMember) => {
    const { id: groupId } = match.params;

    let identity, group;
    if (newMember.displayName && (newMember.upn || newMember.unconfirmed)) {
      identity = await dispatch(
        addGroupMemberIdentities(groupId, [{ id: newMember.id }])
      );
    } else if (newMember.groupIdentifier) {
      group = dispatch(addGroupMemberGroups(groupId, [{ id: newMember.id }]));
    }

    const isErrorAction =
      identity?.type === errorAction(types.ADD_GROUP_MEMBER_IDENTITIES) ||
      group?.type === errorAction(types.ADD_GROUP_MEMBER_GROUPS);

    if (isErrorAction) {
      const title =
        identity?.payload?.title ||
        group?.payload?.title ||
        "An error occurred adding the member";
      const description =
        identity?.payload?.data?.message ||
        group?.payload?.data?.message ||
        "Please try again later";

      notification.error({
        message: title,
        description: description,
      });

      return;
    }

    await getMembers();
  };

  const handleCreateByEmail = async (email) => {
    const { id: groupId } = match.params;
    const identityIds = [{ id: email }];
    const createByEmailResponse = await dispatch(
      addGroupMemberIdentities(groupId, identityIds)
    );
    if (
      createByEmailResponse &&
      createByEmailResponse.type === errorAction(at.ADD_GROUP_MEMBER_IDENTITIES)
    ) {
      showErrorNotification(
        createByEmailResponse.payload.title,
        createByEmailResponse.payload.data.message
      );
    }
    await getMembers();
  };

  if (!isAccessChecked) {
    return (
      <Segment placeholder>
        <Header icon>
          <Icon name="spinner" loading />
          Loading group members...
        </Header>
      </Segment>
    );
  }

  if (blocked) {
    return (
      <Segment placeholder>
        <Header icon>
          <Icon name="lock" />
          You don't have the access rights to view group members
        </Header>
      </Segment>
    );
  }

  const isReplicaGroup =
    selectedGroup?.syncType === "SlaveWithPlaceholders" ||
    selectedGroup?.syncType === "Slave";

  const columns = getDefaultGroupMembersTableColumns(selectedGroup.id);

  return (
    <>
      <EGroupWarning
        selectedGroup={selectedGroup}
        canManageGroup
        showSyncMessage={false}
      />
      {isLimitReached && (
        <Segment color="yellow">
          <p>
            <Icon name="exclamation circle" color={"yellow"} />
            Only a limited number of members are displayed. Please refine your
            search to see more results.
          </p>
        </Segment>
      )}
      <GroupMembersView
        title="Group members"
        titleIcon="user"
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        columns={columns}
        dataSource={members}
        total={total}
        extraButtons={
          <div
            style={{
              gap: "10px",
              display: "flex",
              alignItems: "center",
            }}
          >
            {!selectedGroup.dynamic && (
              <AddMemberSearchModal
                title="Add an user or group"
                searchValue={searchValue}
                members={members}
                isReplicaGroup={isReplicaGroup}
                onSelect={handleAddMember}
                onCreateByEmail={handleCreateByEmail}
              />
            )}
            <CSVModal
              isModalOpen={isImportModalOpen}
              onClose={() => setImportModalOpen(false)}
              group={selectedGroup}
              getMembers={getMembers}
              isDownload={isDownload}
            />
            <Button
              id="export_csv"
              onClick={() => {
                setImportModalOpen(true);
                setIsDownload(true);
              }}
              icon={"download"}
              content="Export"
              disabled={members.length === 0}
              color={"green"}
            />
            {!selectedGroup.dynamic && (
              <Button
                id="mergeButton"
                onClick={() => {
                  setImportModalOpen(true);
                  setIsDownload(false);
                }}
                content="Import"
                icon={"upload"}
                color={"blue"}
                style={{ marginRight: "0.5rem" }}
                disabled={isReplicaGroup}
              />
            )}
          </div>
        }
        loading={loading || !isInitialized}
        onDelete={onDeleteMembers}
        getMembers={handleSearch}
      />
    </>
  );
};

export default GroupMembers;
