import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import StandardGroupCriteria from "./StandardGroupCriteria.js";
import { notification } from "antd";
import { updateGroup, selectGroupById } from "../../../actions/api";
import { types as at, errorAction } from "@authzsvc/api-lib";
import { useDispatch } from "react-redux";
import { getMyDynamicCriteria } from "../../../actions/api.js";
import EGroupWarning from "../EGroupWarning.js";

const GroupCriteria = ({ selectedGroup, canManageGroup }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [criteriaList, setCriteriaList] = useState([]);

  //Handles update criteria for both standard and role
  const handleUpdate = async (values) => {
    setLoading(true);

    const updatedGroup = { ...selectedGroup, ...values };
    const result = await dispatch(updateGroup(updatedGroup.id, updatedGroup));

    if (result.type === errorAction(at.UPDATE_GROUP)) {
      notification["error"]({
        message: result?.payload?.title || "Failed to update group",
        description:
          result?.payload?.data?.message || "Sorry, something went wrong",
      });
    }
    await dispatch(selectGroupById(updatedGroup.id));

    setLoading(false);
  };

  const getCriteria = async () => {
    const criteriaList = await dispatch(getMyDynamicCriteria());

    const isErrorAction =
      criteriaList.type === errorAction(at.GET_MY_DYNAMIC_GROUP_CRITERIA);

    if (isErrorAction) {
      notification.error({
        message: criteriaList.payload.title,
        description: criteriaList.payload.message,
      });
    } else {
      setCriteriaList(criteriaList.payload.data);
    }
  };

  useEffect(() => {
    getCriteria();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <EGroupWarning
        selectedGroup={selectedGroup}
        canManageGroup
        showSyncMessage={false}
      />
      <StandardGroupCriteria
        criteriaOptions={criteriaList}
        selectedGroup={selectedGroup}
        canManageGroup={canManageGroup}
        handleUpdate={handleUpdate}
        loading={loading}
      />
      {/* <RoleGroupCriteria /> */}
    </>
  );
};

GroupCriteria.propTypes = {
  selectedGroup: PropTypes.object,
  canManageGroup: PropTypes.bool,
};

export default GroupCriteria;
