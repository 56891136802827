import {
  FormOutlined,
  InfoCircleOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Checkbox, DatePicker, Divider, Form, Input, Tooltip } from "antd";
import _ from "lodash";
import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Button, Segment } from "semantic-ui-react";
import { searchGroupIdentifier } from "../../actions/api";
import styles from "./Group.module.css";
import { useDispatch } from "react-redux";

import moment from "moment";
import { enableGidAssignment, FormValidationStates } from "../../config";
import {
  PrivacyRadioGroup,
  privacyTypes,
  resourceCategories,
  ResourceCategoryRadioGroup,
  SelfSubscriptionRadioGroup,
  selfSubscriptionTypes,
} from "../../constants";
import { DynamicGroupTypes, GroupSyncTypes } from "../../constants/enums";
import OwnerEdit from "../common/OwnerEdit";
import TooltipInfoIcon from "../common/TooltipInfoIcon";
import AssignAdministratorGroup from "./formHelpers/AssignAdministratorGroup";
import GroupDeletionAction from "./GroupDeletionAction";
import SubscriptionButton from "./SubscriptionButton";
import ComputingGroupAction from "./ComputingGroupAction";
import GroupMembershipModal from "./GroupMembershipModal";
import { getMembershipRestriction } from "../../actions/api";
import { LoadingWrapper } from "@authzsvc/common-react-components";
import { showErrorNotification, formatDateGroup } from "../../util";

const helpText = (error) => {
  return error ? <label className={styles.errorText}>{error}</label> : <></>;
};

const openNotificationsForAllValidationErrors = (formErrors) => {
  Object.keys(formErrors).forEach((key) => {
    const contents = (
      <>
        {formErrors[key].errors.map((errorMessage) => (
          <p key={key}>{errorMessage.message}</p>
        ))}
      </>
    );
    const message = `Validation error for the field: "${key}"`;
    showErrorNotification(message, contents);
  });
};

const formItemLayout = {
  style: { marginBottom: "0px" },
  labelCol: {
    span: 6,
  },
  wrapperCol: {
    span: 18,
  },
};

const GroupEditForm = ({
  handleSubmit,
  form,
  dynamic,
  onSelectOwner,
  group,
  searchGroupIdentifier,
  canManage,
  groupMemberships,
  loading,
  isDisableByInternalState,
}) => {
  const dispatch = useDispatch();

  const [displayNameStatus, setDisplayNameStatus] = useState(null);
  const [displayNameError, setDisplayNameError] = useState(null);
  const [groupIdentifierStatus, setGroupIdentifierStatus] = useState(null);
  const [groupIdentifierError, setGroupIdentifierError] = useState(null);
  const [initialLoading, setInitialLoading] = useState(false);
  const [membershipRestrictionGroups, setMembershipRestrictionGroups] =
    useState([]);
  const [update, setUpdate] = useState(false);
  const { getFieldError, isFieldTouched } = form;

  const administratorsId = form.getFieldValue("administratorsId");

  const groupIdError =
    (isFieldTouched("groupIdentifier") && getFieldError("groupIdentifier")) ||
    groupIdentifierError;
  const groupDisplayNameError =
    (isFieldTouched("displayName") && getFieldError("displayName")) ||
    displayNameError;
  const descriptionError =
    isFieldTouched("description") && getFieldError("description");
  const errors = groupIdError || groupDisplayNameError || descriptionError;

  const loadMembershipRestrictions = async () => {
    setInitialLoading(true);
    const memberships = await dispatch(getMembershipRestriction(group.id));
    setMembershipRestrictionGroups(memberships.payload.data);
    setInitialLoading(false);
  };

  const handleSubmitWrapper = async (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }

    form.validateFields(async (err, values) => {
      if (!err) {
        let processedValues = { ...values, dynamic };

        if (dynamic) {
          processedValues = {
            ...processedValues,
            dynamicGroupType: processedValues.includeExmp
              ? DynamicGroupTypes.FullDynamic
              : DynamicGroupTypes.Dynamic,
          };
        }
        delete processedValues.syncEgroups;

        // Remove hours, minutes, seconds and milliseconds from the date
        processedValues = {
          ...processedValues,
          validityLimit: formatDateGroup(processedValues.validityLimit),
        };

        await handleSubmit(processedValues);
      } else {
        openNotificationsForAllValidationErrors(err);
      }
    });
  };

  /**
   * Creates a decorator for a field type, utility function
   * @param  {[type]} fieldName    the name of the field
   * @param  {[type]} config       config specific to the field
   * @param  {[type]} initialValue the initial value to populate the field
   * @return {[type]}              A field
   */
  const createFieldDecorator = (fieldName, config, initialValue) => {
    const { getFieldDecorator } = form;
    if (
      group !== undefined &&
      group[fieldName] !== undefined &&
      group[fieldName] !== null
    ) {
      config.initialValue = group[fieldName];
      if (fieldName === "validityLimit") {
        config.initialValue = moment(group[fieldName]);
      }
      if (fieldName === "blockingDeadline") {
        config.initialValue = moment(group[fieldName]);
      }
    } else if (initialValue !== undefined) {
      config.initialValue = initialValue;
    }
    return getFieldDecorator(fieldName, config);
  };

  /**
   * Render a warning in case expiration mode is not defined.
   * @return {[type]} [description]
   */
  const renderExpirationDateWarning = () => {
    if (
      !form.getFieldValue("expirationDeadline") &&
      form.isFieldTouched("expirationDeadline")
    ) {
      return (
        <Segment color="yellow">
          <p>
            <InfoCircleOutlined /> Before removing the expiration date, please
            consider whether maintaining this indefinitely conforms with{" "}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="http://cds.cern.ch/record/2651311"
            >
              Operational Circular No.11
            </a>
            {"  "}
            “The processing of personal data at CERN”
          </p>
        </Segment>
      );
    }
    return <></>;
  };

  /**
   * If sync type is !== Master and !== NoSync, then the controls are disabled
   */
  const disabledBySyncType = () => {
    return (
      group.syncType !== GroupSyncTypes.Master &&
      group.syncType !== GroupSyncTypes.NoSync
    );
  };

  const isFullDynamic = () => {
    return group.dynamicGroupType === DynamicGroupTypes.FullDynamic;
  };

  const disableSubscription = () => {
    let disabledSubscription = {
      disabled: false,
      message: "Modify group subscription",
    };
    if (group.selfSubscriptionType === "Closed") {
      disabledSubscription.disabled = true;
      disabledSubscription.message =
        "This group does not allow self subscription.";
    }
    if (group.syncType !== "NoSync" && group.syncType !== "Master") {
      disabledSubscription.disabled = true;
      disabledSubscription.message =
        "This group is managed via E-groups, please visit e-groups.cern.ch to subscribe";
    }
    if (
      group.owner &&
      group.owner.id === "222" &&
      disabledSubscription.disabled
    ) {
      disabledSubscription.disabled = false;
      disabledSubscription.message = "Modify group subscription";
    }
    if (
      groupMemberships &&
      groupMemberships.find((g) => g.id === group.id) &&
      disabledSubscription.disabled
    ) {
      disabledSubscription.disabled = false;
      disabledSubscription.message = "Modify group subscription";
    }
    return disabledSubscription;
  };

  const createButtons = (errors) => {
    return (
      <>
        <>
          <Form.Item {...formItemLayout}>
            <Button
              color="green"
              id="updateGroupButton"
              size="large"
              type="submit"
              disabled={
                !_.isNil(errors) ||
                disabledBySyncType() ||
                isDisableByInternalState
              }
              loading={loading}
            >
              Save
            </Button>
            {group && group.id && (
              <>
                <Divider type="vertical" />
                <Tooltip
                  title={disableSubscription().message}
                  trigger={"hover"}
                >
                  <span>
                    <SubscriptionButton
                      group={group}
                      disabled={
                        disableSubscription().disabled ||
                        isDisableByInternalState
                      }
                      reason={""}
                      open={false}
                    />
                  </span>
                </Tooltip>
                {!disabledBySyncType() && !isDisableByInternalState && (
                  <>
                    <Divider type="vertical" />
                    <GroupDeletionAction groupId={group.id} />
                  </>
                )}
              </>
            )}
          </Form.Item>
        </>
      </>
    );
  };

  const getEditExtraContent = () => {
    return (
      <>
        <Form.Item {...formItemLayout} label="Owner">
          {group && (
            <OwnerEdit
              onSelect={onSelectOwner}
              loading={false}
              group={group}
              disabled={
                disabledBySyncType() || !canManage || isDisableByInternalState
              }
            />
          )}
        </Form.Item>
        {canManage && (
          <>
            {!dynamic && (
              <Form.Item
                {...formItemLayout}
                label={
                  <span>
                    Purge non active users?{" "}
                    <TooltipInfoIcon
                      title="When identities become inactive, e.g. they leave CERN, they will be removed from this group if this checkbox is ticked."
                      size={"small"}
                    />
                  </span>
                }
              >
                {createFieldDecorator(
                  "removeNonActiveMembers",
                  {
                    valuePropName: "checked",
                  },
                  false
                )(
                  <Checkbox
                    disabled={disabledBySyncType() || isDisableByInternalState}
                  />
                )}
              </Form.Item>
            )}
            <Form.Item {...formItemLayout} label="Blocking date">
              {createFieldDecorator(
                "blockingDeadline",
                {}
              )(<DatePicker disabled={true} format={"DD-MM-YYYY"} />)}
            </Form.Item>
          </>
        )}
      </>
    );
  };

  const getDynamicGroupsContent = () => {
    if (!dynamic) {
      return null;
    }

    return (
      <>
        <Form.Item
          {...formItemLayout}
          label={
            <span>
              Include ex-users and ex-employees?{" "}
              <TooltipInfoIcon
                title="Include all people who were registered in the CERN people database at any time to the results of the dynamic group criteria. When disabled (default option) only people with an active CERN registration will appear in the group."
                size={"small"}
              />
            </span>
          }
        >
          {createFieldDecorator(
            "includeExmp",
            {
              valuePropName: "checked",
            },
            isFullDynamic()
          )(<Checkbox disabled={isDisableByInternalState} />)}
        </Form.Item>
      </>
    );
  };

  const validateGroupDisplayName = async (e) => {
    const inputValue = e?.target?.value || "";
    const groupDisplayName = inputValue.trim();
    if (group.displayName === e.target.value) return;

    if (groupDisplayName && !/^\s/.test(e.target.value)) {
      setDisplayNameStatus(FormValidationStates.Validating);
      const resp = await searchGroupIdentifier("DisplayName", [
        groupDisplayName,
      ]);
      if (resp.payload && resp.payload.data) {
        const groupDataUpdated = resp.payload.data.find(
          (o) => o.value === groupDisplayName
        );
        const taken = groupDataUpdated.isTaken;
        let nameStatus = FormValidationStates.Success;
        let nameError = null;
        if (taken && groupDataUpdated.value === groupDisplayName) {
          nameStatus = FormValidationStates.Error;
          nameError = "The Group display name is already taken!";
        }
        setDisplayNameStatus(nameStatus);
        setDisplayNameError(nameError);
      }
    } else {
      setDisplayNameStatus(FormValidationStates.Error);
      setDisplayNameError(
        "The Group display name cannot be empty or start with a space."
      );
    }
  };

  const getGroupIdentifierErrorMessage = (groupIdentifier) => {
    let groupIdentifierError = null;
    if (!groupIdentifier.match(/^[a-z_\-0-9]*$/)) {
      groupIdentifierError =
        "The Group identifier contains invalid characters. Only letters, numbers, " +
        "dashes and underscores are allowed.";
      // } else if (!groupIdentifier.match(/^[a-z0-9].*[a-z0-9]$/)) {
    } else if (
      groupIdentifier.length > 0 &&
      (!groupIdentifier.match(/^[a-z0-9]/) ||
        !groupIdentifier.match(/[a-z0-9]$/))
    ) {
      groupIdentifierError =
        "The Group identifier must start and end with a letter or number.";
    } else if (groupIdentifier.length < 3) {
      groupIdentifierError =
        "The Group identifier is too short (minimum 3 characters).";
    } else if (groupIdentifier.length > 128) {
      groupIdentifierError =
        "The Group identifier is too long (maximum 128 characters).";
    } else if (!groupIdentifier.includes("-")) {
      groupIdentifierError =
        "The Group identifier must contain at least one dash ('-') character.";
    }
    return groupIdentifierError;
  };

  const validateGroupIdentifier = async (e, validateUniqueness) => {
    const groupIdentifier = e.target.value.toLowerCase();
    if (groupIdentifier) {
      setGroupIdentifierStatus(FormValidationStates.Validating);
      let groupIdentifierStatus = FormValidationStates.Error;
      let groupIdentifierError =
        getGroupIdentifierErrorMessage(groupIdentifier);
      if (validateUniqueness && groupIdentifierError === null) {
        const resp = await searchGroupIdentifier("GroupIdentifier", [
          groupIdentifier,
        ]);
        if (resp.payload && resp.payload.data) {
          const taken = resp.payload.data.find(
            (o) => o.value === groupIdentifier
          ).isTaken;
          if (taken) {
            groupIdentifierError = "The Group identifier is already in use.";
          }
        }
      }
      if (groupIdentifierError === null) {
        groupIdentifierStatus = FormValidationStates.Success;
      }
      setGroupIdentifierStatus(groupIdentifierStatus);
      setGroupIdentifierError(groupIdentifierError);
    }
  };

  useEffect(() => {
    (async () => {
      try {
        await loadMembershipRestrictions();
      } catch (error) {
        showErrorNotification("Error loading groups details", error.message);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (update) {
      handleSubmitWrapper();
    }
    setUpdate(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [administratorsId]);

  return (
    <Form onSubmit={handleSubmitWrapper} layout="horizontal">
      {renderExpirationDateWarning()}
      <Form.Item
        {...formItemLayout}
        label="Group identifier"
        hasFeedback
        validateStatus={groupIdentifierStatus}
        help={helpText(groupIdError)}
        rules={[
          {
            required: true,
          },
        ]}
        required={canManage}
      >
        {createFieldDecorator("groupIdentifier", {
          validateTrigger: "onBlur",
          rules: [
            {
              required: true,
              message: "Please input the group's identifier!",
            },
          ],
        })(
          <Input
            id="groupIdentifierInput"
            disabled
            prefix={<UserOutlined className={styles.normalIcon} />}
            placeholder="my-group"
            onBlur={(event) => {
              event.target.value = event.target.value.toLowerCase();
              validateGroupIdentifier(event, true);
            }}
            onChange={(event) => {
              event.target.value = event.target.value.toLowerCase();
              validateGroupIdentifier(event, false);
            }}
          />
        )}
      </Form.Item>
      <Form.Item
        {...formItemLayout}
        label="Group display name"
        hasFeedback
        validateStatus={displayNameStatus}
        help={helpText(displayNameError)}
        rules={[
          {
            required: true,
          },
        ]}
        required={canManage}
      >
        {createFieldDecorator("displayName", {
          rules: [
            {
              required: true,
              message: "Please input the group's name!",
            },
          ],
        })(
          <Input
            id="groupDisplayNameInput"
            disabled={
              (disabledBySyncType() && !displayNameError) ||
              !canManage ||
              isDisableByInternalState
            }
            placeholder="My group"
            onBlur={validateGroupDisplayName}
          />
        )}
      </Form.Item>
      <Form.Item
        {...formItemLayout}
        label="Description"
        validateStatus={descriptionError ? "error" : ""}
        help={descriptionError || ""}
        rules={[
          {
            required: true,
          },
        ]}
        required={canManage}
      >
        {createFieldDecorator("description", {
          rules: [
            {
              required: true,
              message: "Please input the group's description!",
            },
            {
              pattern: /\S+/,
              message: "Description cannot be only spaces!",
            },
          ],
        })(
          <Input.TextArea
            id="groupDescriptionInput"
            disabled={
              disabledBySyncType() || !canManage || isDisableByInternalState
            }
            prefix={<FormOutlined className={styles.normalIcon} />}
            placeholder="Group description"
          />
        )}
      </Form.Item>
      <Form.Item {...formItemLayout} label="Administrator group">
        {createFieldDecorator(
          "administratorsId",
          {}
        )(
          <AssignAdministratorGroup
            disabled={
              disabledBySyncType() || !canManage || isDisableByInternalState
            }
            onChange={() => setUpdate(true)}
          />
        )}
      </Form.Item>
      {getEditExtraContent()}
      {canManage && (
        <>
          <Form.Item
            {...formItemLayout}
            layout="inline"
            label={
              <span>
                Resource category{" "}
                <TooltipInfoIcon
                  title="Test - will be removed in a short time; Official - will be reassigned to your supervisor in case of departure; Personal - will be deleted in case of departure."
                  size={"small"}
                />
              </span>
            }
          >
            {createFieldDecorator(
              "resourceCategory",
              {},
              resourceCategories.Test
            )(
              <ResourceCategoryRadioGroup
                disabled={disabledBySyncType() || isDisableByInternalState}
              />
            )}
          </Form.Item>

          <Form.Item
            {...formItemLayout}
            label="Expiration date"
            layout="inline"
          >
            {createFieldDecorator(
              "validityLimit",
              {}
            )(
              <DatePicker
                disabledDate={(c) => c.isBefore(moment())}
                disabled={disabledBySyncType() || isDisableByInternalState}
                placeholder="dd-mm-yyyy"
                format={"DD-MM-YYYY"}
              />
            )}
          </Form.Item>

          {!dynamic && (
            <Form.Item {...formItemLayout} label="Self-subscription policy">
              {createFieldDecorator(
                "selfSubscriptionType",
                {},
                selfSubscriptionTypes.Closed
              )(
                <SelfSubscriptionRadioGroup
                  disabled={disabledBySyncType() || isDisableByInternalState}
                />
              )}
            </Form.Item>
          )}
          {form.getFieldValue("selfSubscriptionType") !==
            selfSubscriptionTypes.Closed && (
            <Form.Item {...formItemLayout} label="Approval required">
              {createFieldDecorator(
                "approvalRequired",
                {
                  valuePropName: "checked",
                },
                false
              )(
                <Checkbox
                  disabled={disabledBySyncType() || isDisableByInternalState}
                />
              )}
            </Form.Item>
          )}
          <Form.Item {...formItemLayout} label="Membership visibility">
            {createFieldDecorator(
              "privacyType",
              {},
              privacyTypes.Admins
            )(
              <PrivacyRadioGroup
                disabled={disabledBySyncType() || isDisableByInternalState}
              />
            )}
            <br />
            <i style={{ color: "red" }}>
              The e-group memberships will be shared with other applications
              that may not provide the same level of confidentiality.
            </i>
          </Form.Item>
          {enableGidAssignment && (
            <Form.Item
              {...formItemLayout}
              label={
                <span>
                  {" "}
                  Set as Computing Group?{" "}
                  <TooltipInfoIcon
                    title="Allow a group to have a gid assigned to it and become a computing group. Note that if a group becomes a computing group then this cannot be reversed!"
                    size={"small"}
                  />
                </span>
              }
            >
              {createFieldDecorator(
                "setComputingGroup",
                {
                  valuePropName: "checked",
                },
                false
              )(
                <ComputingGroupAction
                  groupComputingGroup={group.isComputingGroup}
                  groupId={group.id}
                />
              )}
            </Form.Item>
          )}

          {getDynamicGroupsContent()}
          <LoadingWrapper loading={initialLoading}>
            <Form.Item
              {...formItemLayout}
              label={
                <span>
                  Membership Restrictions{" "}
                  <TooltipInfoIcon
                    title="If specified, non-admin users will only be able to add members that are already members of one of the restriction groups. No restrictions are applied if the list is empty."
                    size={"small"}
                  />
                </span>
              }
            >
              <div
                style={{
                  display: "flex",
                  position: "relative",
                  alignItems: "center",
                  justifyContent: "flex-start",
                }}
              >
                <GroupMembershipModal
                  groupId={group.id}
                  disabled={disabledBySyncType() || isDisableByInternalState}
                  onCancel={loadMembershipRestrictions}
                />
              </div>

              <Segment
                color="yellow"
                style={{ width: "80%", maxHeight: "150px", overflowY: "auto" }}
              >
                <ul
                  style={{
                    margin: 0,
                    padding: "0 10px",
                    listStyleType: "disc",
                  }}
                >
                  {membershipRestrictionGroups?.map((group, index) => (
                    <li key={index} style={{ fontSize: "14px" }}>
                      {group.displayName}
                    </li>
                  ))}
                  {!membershipRestrictionGroups?.length && (
                    <p style={{ fontStyle: "italic", color: "gray" }}>
                      No membership restrictions configured.
                    </p>
                  )}
                </ul>
              </Segment>

              <div
                style={{
                  marginTop: "16px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <span>
                  Enforce membership restrictions
                  <TooltipInfoIcon title="If the option to enforce membership restrictions is selected, members that are not members of at least one of the following groups will be periodically removed. Restrictions for new group members will also be enforced for admin users. No restrictions are applied if the list is empty." />
                  :
                </span>
                {createFieldDecorator(
                  "enforceMembershipRestrictions",
                  {
                    valuePropName: "checked",
                  },
                  group.enforceMembershipRestrictions
                )(
                  <Checkbox
                    disabled={disabledBySyncType() || isDisableByInternalState}
                    style={{ marginLeft: "8px" }}
                  />
                )}
              </div>
            </Form.Item>
          </LoadingWrapper>
          {createButtons(errors)}
        </>
      )}
    </Form>
  );
};

GroupEditForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  form: PropTypes.shape({
    isFieldTouched: PropTypes.func,
    getFieldError: PropTypes.func,
    validateFields: PropTypes.func,
    getFieldDecorator: PropTypes.func,
    getFieldValue: PropTypes.func,
  }),
  dynamic: PropTypes.bool.isRequired,
  onSelectOwner: PropTypes.func,
  group: PropTypes.object,
  searchGroupIdentifier: PropTypes.func.isRequired,
  canManage: PropTypes.bool,
};

const WrappedGroupEditForm = Form.create({ name: "group_creation_form" })(
  GroupEditForm,
  searchGroupIdentifier
);

export default connect(null, {
  searchGroupIdentifier,
})(WrappedGroupEditForm);
