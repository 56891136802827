import React from "react";
import PropTypes from "prop-types";
import { Icon, Segment } from "semantic-ui-react";

const EGroupWarning = ({ selectedGroup, canManageGroup, showSyncMessage }) => {
  const isReplicaGroup =
    selectedGroup.syncType === "SlaveWithPlaceholders" ||
    selectedGroup.syncType === "Slave";

  const syncStatusMessages = {
    NotSynced: "This group has not yet been synchronized.",
    Synchronized: "Group is correctly synchronized.",
    OwnerError:
      "This group has an owner problem, preventing synchronization. Please verify that a valid owner is assigned.",
    MetadataError:
      "This group has a metadata error, preventing synchronization.",
    DynamicError: "This group has a dynamic error, preventing synchronization.",
    GroupMembershipError:
      "This group has a group membership error, preventing synchronization.",
  };

  const getColor = () => {
    switch (selectedGroup.syncStatus) {
      case "NotSynced":
        return "yellow";

      case "Synchronized":
        return "green";

      case "OwnerError":
      case "MetadataError":
      case "DynamicError":
      case "GroupMembershipError":
        return "red";

      default:
        return "yellow";
    }
  };

  const renderSyncStatusMessage = () => {
    return (
      <p>
        <Icon name="exclamation circle" color={getColor()} />
        {syncStatusMessages[selectedGroup.syncStatus]}
      </p>
    );
  };

  const renderSyncTypeMessage = () => {
    return (
      <>
        <p>
          <Icon name="exclamation circle" color={"red"} /> You cannot modify
          this group because it is being synchronized from the{" "}
          <a
            href={`https://e-groups.cern.ch/e-groups/Egroup.do?egroupName=${selectedGroup.groupIdentifier}`}
          >
            legacy EGroups service
          </a>
          .
        </p>

        <p>
          To make this group editable and migrate it to Grappa, please check the
          Synchronization Options tab above.
        </p>
      </>
    );
  };

  return (
    <>
      {canManageGroup && (isReplicaGroup || showSyncMessage) && (
        <Segment color={showSyncMessage ? getColor() : "red"}>
          <>
            {isReplicaGroup && !showSyncMessage && renderSyncTypeMessage()}

            {showSyncMessage && renderSyncStatusMessage()}
          </>
        </Segment>
      )}
    </>
  );
};

EGroupWarning.propTypes = {
  selectedGroup: PropTypes.object,
  canManageGroup: PropTypes.bool,
  showSyncMessage: PropTypes.bool,
};

export default EGroupWarning;
