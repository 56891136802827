import React, { useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Segment, Header, Placeholder } from "semantic-ui-react";
import TooltipInfoIcon from "../../common/TooltipInfoIcon";
import { SyncOutlined } from "@ant-design/icons";
import GroupSyncTypeSelector from "./GroupSyncTypeSelector";
import { selectGroupById, patchComputingGroup } from "../../../actions/api";
import { successAction, errorAction, types as at } from "@authzsvc/api-lib";
import { showErrorNotification } from "../../../util";
import EGroupWarning from "../EGroupWarning";
import { notification } from "antd";

const GroupSyncTypePanel = ({
  selectedGroup,
  canManageGroup,
  // methods
  selectGroupById,
  patchComputingGroup,
}) => {
  const [loading, setLoading] = useState(false);

  const handleGroupTypeChange = async (groupType) => {
    if (
      (groupType === "SlaveWithPlaceholders" || groupType === "Slave") &&
      selectedGroup.syncType === "Master" &&
      selectedGroup.dynamic
    ) {
      showErrorNotification(
        "Cannot convert a primary dynamic group to slave!",
        "A dynamic group cannot be converted to a slave group."
      );
    } else {
      setLoading(true);
      const groupId = selectedGroup.id;
      const result = await patchComputingGroup(groupId, [
        {
          value: groupType,
          path: "/syncType",
          op: "replace",
        },
      ]);
      if (result.type === successAction(at.PATCH_GROUP_BY_ID)) {
        notification["success"]({
          message: "Successfully updated group",
        });
      } else if (result.type === errorAction(at.PATCH_GROUP_BY_ID)) {
        showErrorNotification(
          result.payload.title,
          result.payload.data.message
        );
      }
      await selectGroupById(groupId);
      setLoading(false);
    }
  };

  return (
    <>
      <EGroupWarning
        selectedGroup={selectedGroup}
        canManageGroup
        showSyncMessage={true}
      />
      <Segment color="blue">
        <Header size="medium">
          <SyncOutlined /> Group synchronization type{" "}
          <TooltipInfoIcon
            title={
              "The type of synchronization this group is using. Slave groups are read-only and synced from E-Groups, while primary groups are synchronized to E-Groups."
            }
          />
        </Header>
        {loading && (
          <Placeholder>
            <Placeholder.Line />
            <Placeholder.Line />
            <Placeholder.Line />
          </Placeholder>
        )}
        {!loading && selectedGroup && selectedGroup.id && (
          <GroupSyncTypeSelector
            loading={loading}
            onChange={handleGroupTypeChange}
            groupType={selectedGroup.syncType}
          />
        )}
      </Segment>
    </>
  );
};

GroupSyncTypePanel.propTypes = {
  selectedGroup: PropTypes.object.isRequired,
  selectGroupById: PropTypes.func.isRequired,
  patchComputingGroup: PropTypes.func.isRequired,
};

export default connect(
  (state) => ({
    selectedGroup: state.groups.selectedGroup,
  }),
  { selectGroupById, patchComputingGroup }
)(GroupSyncTypePanel);
