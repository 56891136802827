export const GroupSyncTypes = Object.freeze({
  Slave: "Slave",
  Master: "Master",
  NoSync: "NoSync",
});

export const DynamicGroupTypes = Object.freeze({
  Static: "Static",
  Dynamic: "Dynamic",
  FullDynamic: "FullDynamic",
});

export const GroupSyncTypeNames = Object.freeze({
  Slave: "Replica",
  Master: "Primary",
  NoSync: "Not Synced",
});

export const GroupSyncDescriptions = Object.freeze({
  Slave:
    "This group will be synchronized with all its member accounts and groups from the E-Groups interface, including e-mails and lightweight accounts. Any modification to a replica group will be overridden at the next synchronization.",
  Master:
    "This group 'owns' the e-group, and will synchronize all its CERN members to E-Groups. Modifying the e-group on the E-Groups portal will be temporary and overwritten at the next synchronization. Members without a CERN account and e-mail memberships will not be synchronized and will be deleted from the e-group.",
  NoSync:
    "This group will not be synchronized with E-groups. This does not automatically remove an existing group from E-groups, which has to be done manually.",
});

export const GroupValidInternalStates = Object.freeze({
  Active: "Active",
  GracePeriod: "GracePeriod",
  Created: "Created",
});
