import React from "react";
import { Header, Segment } from "semantic-ui-react";
import TooltipInfoIcon from "../common/TooltipInfoIcon";
import PropTypes from "prop-types";

export const InternalStateWarning = (selectedGroup) => {
  const internalState = selectedGroup.internalState;

  const internalStateMessages = {
    ActionRequired:
      "This group is not currently editable. Please contact the service desk.",
    Blocked:
      "It is not possible to perform any action on this group because it is blocked.",
    Archived:
      "It is not possible to perform any action on this group because it has been archived. Please contact the service desk if you need to recover the group.",
    Deleted:
      "It is not possible to perform any action on this group because it has been deleted. Please contact the service desk if you need to recover the group.",
  };

  const getHeader = () => {
    return internalState === "ActionRequired" ? "Action Required" : "Warning";
  };

  const getMessage = () => {
    return internalStateMessages[internalState];
  };
  return (
    <Segment color="red">
      <Header size="medium">
        {getHeader()}
        <TooltipInfoIcon
          title={
            getMessage() ||
            "This group is not currently editable. Please contact the service desk."
          }
        />
        <p>
          {getMessage() ||
            "This group is not currently editable. Please contact the service desk."}
        </p>
      </Header>
    </Segment>
  );
};

InternalStateWarning.propTypes = {
  selectedGroup: PropTypes.object.isRequired,
};
