export const rolesCriteria = [
  {
    id: "role",
    description: "Role 2nd Target Type",
    exampleValues: "EXPERIMENT, INTERSECTION, ...",
    datatype: "string",
    deprecated: false,
    restricted: false,
  },
  {
    id: "roles.role_type",
    description: "The role of the person.",
    exampleValues: "Role A",
    datatype: "string",
    deprecated: false,
    restricted: false,
  },
  {
    id: "roles.entity_id",
    description: "Role 1st Target ID",
    exampleValues: "FAP-BC-AS, ATLAS, T133700, 71607, ...",
    datatype: "string",
    deprecated: false,
    restricted: false,
  },
  {
    id: "roles.entity_type",
    description: "Role 1st Target Type",
    exampleValues: "EXPERIMENT, INTERSECTION, ...",
    datatype: "string",
    deprecated: false,
    restricted: false,
  },
  {
    id: "roles.entity2_id",
    description: "Role 2nd Target ID",
    exampleValues: "FAP-BC-AS, ATLAS, T133700, 71607, ...",
    datatype: "string",
    deprecated: false,
    restricted: false,
  },
];

// List of standard operators with their key, display text, and backend value.
export const standardOperators = [
  { key: "equals", text: "Equals", value: "Eq" },
  { key: "not_equals", text: "Not Equals", value: "Neq" },
  { key: "less_than", text: "Less Than", value: "Lt" },
  {
    key: "less_than_or_equal",
    text: "Less Than or Equal",
    value: "Le",
  },
  { key: "greater_than", text: "Greater Than", value: "Gt" },
  {
    key: "greater_than_or_equal",
    text: "Greater Than or Equal",
    value: "Ge",
  },
  { key: "like", text: "Like", value: "Like" },
  { key: "not_like", text: "Not Like", value: "NotLike" },
  { key: "between", text: "Between", value: "Between" },
  { key: "not_between", text: "Not Between", value: "NotBetween" },
  { key: "in", text: "In", value: "In" },
  { key: "not_in", text: "Not In", value: "NotIn" },
  { key: "is_null", text: "Null", value: "IsNull" },
  { key: "is_not_null", text: "Not Null", value: "IsNotNull" },
];

// Mapping of data types (string, number, boolean) to valid operator keys.
// This ensures only relevant operators are available for a given data type.
const dataTypeToOperators = {
  string: [
    "equals",
    "not_equals",
    "like",
    "not_like",
    "in",
    "not_in",
    "is_null",
    "is_not_null",
  ],
  number: [
    "equals",
    "not_equals",
    "less_than",
    "less_than_or_equal",
    "greater_than",
    "greater_than_or_equal",
    "between",
    "not_between",
    "in",
    "not_in",
    "is_null",
    "is_not_null",
  ],
  boolean: ["equals", "not_equals", "is_null", "is_not_null"],
};

/**
 * Filters operators based on the selected data type.
 * This function maps the provided data type to its allowed operators
 * and returns the corresponding entries from the `standardOperators` array.
 *
 * @param {string} dataType - The selected data type (e.g., 'string', 'number', 'boolean').
 * @returns {Array} - List of operators valid for the selected data type.
 */
export const getOperatorsForDataType = (dataType) => {
  // Retrieve the list of valid operator keys for the data type.
  const validOperators = dataTypeToOperators[dataType] || [];
  // Filter the `standardOperators` to include only those with keys in `validOperators`.
  return standardOperators.filter((operator) =>
    validOperators.includes(operator.key)
  );
};

/**
 * Parses raw criteria into a standard format for processing.
 *
 * - If no criteria are provided, a default condition is returned.
 * - For each criterion:
 *   - If the operator is "In" or "NotIn", the `values` string is split into an array.
 *   - Each criterion is transformed into an object with `left`, `operator`, and `right`.
 * - If multiple conditions exist, they are grouped with an "And" operator.
 *
 * @param {Array} criteria - Raw criteria data (e.g., [{ field, operator, values }]).
 * @returns {Object} - Parsed criteria in a standard format.
 */
export const parseNewStandardCriteria = (criteria, groupCriteria) => {
  // Return a default condition if criteria is empty or undefined.
  if (!criteria || criteria.length === 0) {
    return;
  }

  const rolesCriteria = groupCriteria?.conditions?.find(
    (criteria) => criteria.operator === "Or"
  );

  // Process each criterion into a standardized format.
  const parsedConditions = criteria.map((item) => {
    let values = item?.values || ""; // Default to an empty string if `values` is undefined.
    // Convert `values` to an array for "In" or "NotIn" operators.
    if (item.operator === "In" || item.operator === "NotIn") {
      if (typeof values === "string") {
        values = values.split(",").map((value) => value.trim());
      }
    }

    return {
      left: item.field,
      operator: item.operator,
      right: values,
    };
  });

  // Only add rolesCriteria if it exists (is not undefined or null).
  if (rolesCriteria) {
    parsedConditions.push(rolesCriteria);
  }

  // If multiple conditions exist, group them under an "And" operator.
  return {
    operator: "And",
    conditions: parsedConditions,
  };
};

/**
 * Parses grouped criteria back into the original flat format.
 *
 * - If the criteria use an "Or" operator, an empty array is returned (unsupported).
 * - For single conditions (without `conditions` array), the condition is restructured.
 * - For grouped conditions:
 *   - Filters out any conditions with an "Or" operator (unsupported).
 *   - Converts each condition back to its original format.
 *
 * @param {Object} parsedCriteria - Parsed criteria object with `operator` and `conditions`.
 * @returns {Array} - List of conditions in the original flat format.
 */
export const parseStandardGroupCriteria = (parsedCriteria) => {
  // Return an empty array if the operator is "Or" (unsupported case).
  if (parsedCriteria.operator === "Or") return [];

  // Handle single condition (no `conditions` array present)
  if (!parsedCriteria.conditions) {
    return [
      {
        field: parsedCriteria.left,
        operator: parsedCriteria.operator,
        values: parsedCriteria.right,
      },
    ];
  }

  // Handle grouped conditions by filtering and mapping them back to the original format.
  return parsedCriteria.conditions
    .filter((item) => item.operator !== "Or")
    .map((condition) => ({
      field: condition.left,
      operator: condition.operator,
      values: condition.right,
    }));
};
