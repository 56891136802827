import React, { useState } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { deleteGroupById, getCurrentIdentityGroups } from "../../actions/api";
import { Popconfirm } from "antd";
import { Button, Icon } from "semantic-ui-react";
import { clearSelectedGroup } from "../../actions";
import { errorAction, types } from "@authzsvc/api-lib";
import { showErrorNotification } from "../../util";

const GroupDeletionAction = ({ groupId, size = "small" }) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  const handleDelete = async () => {
    setLoading(true);

    const deletionResponse = await dispatch(deleteGroupById(groupId));

    if (deletionResponse?.type === errorAction(types.DELETE_GROUP_BY_ID)) {
      showErrorNotification(
        deletionResponse.payload.title,
        deletionResponse.payload.data.message
      );
      setLoading(false);
      return;
    }

    dispatch(clearSelectedGroup());
    setLoading(false);
    await dispatch(getCurrentIdentityGroups());
    history.push("/groups/admin");
  };

  return (
    <Popconfirm
      title="Are you sure you want to delete this group?"
      onConfirm={handleDelete}
      okText="Yes"
      cancelText="No"
    >
      <Button id="deleteGroupButton" loading={loading} negative icon>
        <Icon size={size} name="trash alternate" />
      </Button>
    </Popconfirm>
  );
};

GroupDeletionAction.propTypes = {
  groupId: PropTypes.string.isRequired,
  size: PropTypes.string,
};

export default GroupDeletionAction;
