import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { notification } from "antd";
import { Segment, Header, Button, Icon, Modal } from "semantic-ui-react";
import { ContentTable, SearchBar } from "@authzsvc/common-react-components";
import { LoadingWrapper } from "@authzsvc/common-react-components";

const GroupMembersView = ({
  title,
  titleIcon,
  searchValue,
  setSearchValue,
  columns,
  dataSource,
  total,
  extraButtons,
  loading,
  onDelete,
  getMembers,
}) => {
  const [selectedRows, setSelectedRows] = useState([]);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [sortedDataSource, setSortedDataSource] = useState(dataSource);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedDetails, setSelectedDetails] = useState([]); // State to store detailed information
  const [totalLabel, setTotalLabel] = useState("");

  const sortDataSource = (data) => {
    return data.sort((a, b) => {
      const nameA = a.memberIdentity
        ? a.memberIdentity.displayName.toLowerCase()
        : a.memberGroup.displayName.toLowerCase();
      const nameB = b.memberIdentity
        ? b.memberIdentity.displayName.toLowerCase()
        : b.memberGroup.displayName.toLowerCase();
      return nameA.localeCompare(nameB);
    });
  };

  useEffect(() => {
    const sortedData = sortDataSource([...dataSource]);
    setSortedDataSource(sortedData);
    const label = searchValue
      ? ` (Total Members Found: ${sortedData.length})`
      : ` (Total Members: ${total})`;
    setTotalLabel(label);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataSource, total]);

  const showDeleteModal = () => {
    setIsModalOpen(true);
  };

  const handleConfirmDelete = async () => {
    setIsModalOpen(false);
    setLoadingDelete(true);
    await onDelete(selectedRows);
    setSelectedRows([]);
    setSelectedDetails([]);
    setSearchValue("");
    setLoadingDelete(false);

    notification["success"]({
      message: "Members removed successfully!",
    });
  };

  const handleCancelDelete = () => {
    setIsModalOpen(false);
  };

  const onRowSelection = (selectedRowKeys) => {
    const selectedDetails = selectedRowKeys.map((key) => {
      const row = dataSource.find(
        (item) =>
          (item.memberIdentity && item.memberIdentity.id === key) ||
          (item.memberGroup && item.memberGroup.id === key)
      );

      return {
        id: row.memberIdentity ? row.memberIdentity.id : row.memberGroup.id,
        type: row.memberIdentity ? "identity" : "group",
        displayName: row.memberIdentity
          ? `${row.memberIdentity.displayName} (${row.memberIdentity.upn})`
          : row.memberGroup.groupIdentifier,
      };
    });

    setSelectedRows(selectedDetails);
    setSelectedDetails(selectedDetails.map((detail) => detail.displayName)); // Collect detailed information
  };

  const rowSelection = {
    onChange: onRowSelection,
    getCheckboxProps: (record) => ({
      name: record.displayName,
    }),
  };

  const hasSelections = selectedRows.length > 0;

  const getRowKey = (record) => {
    if (record.memberIdentity) {
      return record.memberIdentity.id;
    } else if (record.memberGroup) {
      return record.memberGroup.id;
    }
  };

  return (
    <Segment color="blue">
      <Header size="small">
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div>
            {titleIcon && <Icon size="large" name={titleIcon} />}
            {title}
            {totalLabel}
          </div>

          {extraButtons}
        </div>
      </Header>
      <SearchBar
        onSearch={getMembers}
        searchValue={searchValue}
        onSearchValueChange={setSearchValue}
        filter={true}
      />
      <LoadingWrapper loading={loading}>
        <Segment basic>
          <ContentTable
            loading={loading}
            rowKey={getRowKey}
            pagination={{
              showSizeChanger: true,
              hideOnSinglePage: false,
              pageSizeOptions: ["10", "20", "50", "100"],
            }}
            columns={columns}
            dataSource={sortedDataSource}
            rowSelection={rowSelection}
          />
        </Segment>
        <Segment basic>
          <Button
            id="deleteSelectionsButton"
            loading={loadingDelete}
            disabled={!hasSelections}
            onClick={showDeleteModal}
            negative
            icon="trash alternate"
          />
        </Segment>

        <Modal open={isModalOpen} onClose={handleCancelDelete} size="small">
          <Header icon="trash" content="Confirm Delete" />
          <Modal.Content>
            <p>Are you sure you want to delete the following items?</p>
            <ul>
              {selectedDetails.map((detail, index) => (
                <li key={index}>{detail}</li>
              ))}
            </ul>
          </Modal.Content>
          <Modal.Actions>
            <Button color="red" onClick={handleCancelDelete}>
              <Icon name="remove" /> No
            </Button>
            <Button color="green" onClick={handleConfirmDelete}>
              <Icon name="checkmark" /> Yes
            </Button>
          </Modal.Actions>
        </Modal>
      </LoadingWrapper>
    </Segment>
  );
};

GroupMembersView.propTypes = {
  title: PropTypes.string.isRequired,
  titleIcon: PropTypes.string,
  searchValue: PropTypes.string.isRequired,
  setSearchValue: PropTypes.func.isRequired,
  columns: PropTypes.array.isRequired,
  dataSource: PropTypes.array.isRequired,
  total: PropTypes.number.isRequired,
  extraButtons: PropTypes.node,
  loading: PropTypes.bool.isRequired,
  onDelete: PropTypes.func.isRequired,
  getMembers: PropTypes.func.isRequired,
};

export default GroupMembersView;
