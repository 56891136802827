import React from "react";
import { NavLink } from "react-router-dom";
import Owner from "../components/common/Owner";
import { Radio } from "antd";
import { GroupSyncTypeNames } from "./enums";
import GroupMembershipComment from "../components/common/GroupMembershipComment";
import TooltipInfoIcon from "../components/common/TooltipInfoIcon";

export const privacyTypes = Object.freeze({
  Members: "Members",
  Open: "Open",
  Admins: "Admins",
});

export const selfSubscriptionTypes = Object.freeze({
  Closed: "Closed",
  Open: "Open",
  CernUsers: "CernUsers",
});

export const resourceCategories = Object.freeze({
  Undefined: "Undefined",
  Official: "Official",
  Personal: "Personal",
  Test: "Test",
});

export const defaultGroupTableColumns = [
  {
    title: "Name",
    key: "groupIdentifier",
    dataIndex: "groupIdentifier",
    render: (text, group) => (
      <NavLink to={`/group/${group.groupIdentifier}/details`}>{text}</NavLink>
    ),
  },
  {
    title: "Type",
    dataIndex: "dynamic",
    render: (value) => (value ? <span>Dynamic</span> : <span>Static</span>),
  },
  {
    title: "Sync Type",
    dataIndex: "syncType",
    render: (syncType) => GroupSyncTypeNames[syncType],
  },
  {
    title: "Description",
    dataIndex: "description",
  },
  {
    title: "Blocked Status",
    dataIndex: "blocked",
    render: (blocked) =>
      blocked ? <span>Blocked</span> : <span>Enabled</span>,
  },
  {
    title: "Owner",
    dataIndex: "owner",
    render: (owner) => (owner ? <Owner owner={owner} /> : <span>None</span>),
  },
];

export const getDefaultGroupMembersTableColumns = (selectedGroupId) => [
  {
    title: "Identifier",
    key: "upn" || "groupIdentifier",
    dataIndex: "displayName" || "groupIdentifier",
    render: (_, record) => {
      if (record.memberGroup) {
        return (
          <NavLink to={`/group/${record.memberGroup.groupIdentifier}/details`}>
            {record.memberGroup.displayName}
          </NavLink>
        );
      } else {
        const upn = record.memberIdentity?.upn || "pending confirmation";
        return (
          <div style={{ lineHeight: "1.3" }}>
            <span>
              {record.memberIdentity.displayName} ({upn})
            </span>
            {record.memberIdentity.email && (
              <>
                <br />
                <span style={{ color: "gray", fontSize: "0.8em" }}>
                  {record.memberIdentity.email}
                </span>
              </>
            )}
          </div>
        );
      }
    },
  },
  {
    title: "Member type",
    dataIndex: "type",
    render: (_, record) => {
      if (record.memberGroup) {
        return "Group";
      } else {
        return record.memberIdentity.type;
      }
    },
  },
  {
    title: "Comment",
    dataIndex: "comment",
    render: (_, record) => {
      return (
        <GroupMembershipComment
          record={record}
          selectedGroupId={selectedGroupId}
        />
      );
    },
  },
];

export const SelfSubscriptionRadioGroup = React.forwardRef((props, ref) => (
  <Radio.Group
    ref={ref}
    disabled={props.disabled}
    {...props}
    defaultValue={selfSubscriptionTypes.Closed}
  >
    <Radio {...props} value={selfSubscriptionTypes.Closed}>
      Closed{" "}
      <TooltipInfoIcon
        title="Self-Subscription is closed. Group membership is managed by the group administrators."
        size={"small"}
      />
    </Radio>
    <Radio {...props} value={selfSubscriptionTypes.Open}>
      Open{" "}
      <TooltipInfoIcon
        title="Self-Subscription is available to anyone with or without a CERN account."
        size={"small"}
      />
    </Radio>
    <Radio {...props} value={selfSubscriptionTypes.CernUsers}>
      CERN Users{" "}
      <TooltipInfoIcon
        title="Self-Subscription is available to people with a CERN account. Other members can be added by the group administrators."
        size={"small"}
      />
    </Radio>
  </Radio.Group>
));

export const PrivacyRadioGroup = React.forwardRef((props, ref) => (
  <Radio.Group
    ref={ref}
    disabled={props.disabled}
    {...props}
    defaultValue={privacyTypes.Admins}
  >
    <Radio {...props} value={privacyTypes.Admins}>
      Group administrators
    </Radio>
    <Radio {...props} value={privacyTypes.Open}>
      Open
    </Radio>
    <Radio {...props} value={privacyTypes.Members}>
      Members
    </Radio>
  </Radio.Group>
));

export const ResourceCategoryRadioGroup = React.forwardRef((props, ref) => (
  <Radio.Group
    ref={ref}
    disabled={props.disabled}
    {...props}
    defaultValue={resourceCategories.Test}
  >
    <Radio {...props} value={resourceCategories.Test}>
      Test
    </Radio>
    <Radio {...props} value={resourceCategories.Official}>
      Official
    </Radio>
    <Radio {...props} value={resourceCategories.Personal}>
      Personal
    </Radio>
  </Radio.Group>
));

export const DEFAULT_GROUP_MEMBERS_TABLE_QUERY_FIELDS = [
  ...getDefaultGroupMembersTableColumns(null).map((i) => i.dataIndex),
  "unconfirmed",
  "unconfirmedEmail",
];

export const DEFAULT_GROUP_TABLE_QUERY_FIELDS = defaultGroupTableColumns.map(
  (i) => i.dataIndex
);
