import React, { useState, useEffect, useCallback } from "react";
import { Tree } from "antd";
import { BranchesOutlined } from "@ant-design/icons";
import { Segment, Header } from "semantic-ui-react";
import { connect } from "react-redux";
import styles from "./Group.module.css";
import {
  getGroupMembersForTreeView,
  selectGroupParentForTreeView,
} from "../../actions/api";
import TooltipInfoIcon from "../common/TooltipInfoIcon";
import { LoadingWrapper } from "@authzsvc/common-react-components";

const GroupTreeView = ({
  match,
  history,
  selectGroupParentForTreeView,
  getGroupMembersForTreeView,
}) => {
  const [treeData, setTreeData] = useState([]);
  const [loading, setLoading] = useState(false);
  const fields = ["groupIdentifier", "memberGroupIds"];

  useEffect(() => {
    const fetchGroupData = async () => {
      setLoading(true);
      const id = match.params.id;
      const groupResponse = await selectGroupParentForTreeView(id, fields);
      const parentResponses = groupResponse.payload.data;

      if (parentResponses.length > 0) {
        setTreeData([{ ...parentResponses[0], children: [] }]);
      }
      setLoading(false);
    };
    fetchGroupData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match.params.id, selectGroupParentForTreeView]);

  const onLoadData = useCallback(
    async (node) => {
      const { key } = node;
      const res = await getGroupMembersForTreeView(key, fields);
      if (res.payload.data) {
        setTreeData((prevTreeData) =>
          prevTreeData.map((item) =>
            item.id === key ? { ...item, children: res.payload.data } : item
          )
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getGroupMembersForTreeView]
  );

  const navigateToGroup = useCallback(
    (selection) => {
      if (selection.length > 0) {
        history.push(`/group/${selection[0]}/details`);
      }
    },
    [history]
  );

  return (
    <Segment color="blue">
      <LoadingWrapper loading={loading}>
        <Header size="medium">
          Group tree data{" "}
          <TooltipInfoIcon title="Groups that contain other groups can be expanded by clicking." />
        </Header>
      </LoadingWrapper>
      <Tree
        className={styles.overflowControl}
        onSelect={navigateToGroup}
        showIcon
        treeData={treeData.map((item) => ({
          title: item.groupIdentifier,
          key: item.id,
          isLeaf: !(item.memberGroupIds && item.memberGroupIds.length > 0),
          icon: <BranchesOutlined />,
          children: item.children || [],
        }))}
        loadData={onLoadData}
      />
    </Segment>
  );
};

export default connect(null, {
  getGroupMembersForTreeView,
  selectGroupParentForTreeView,
})(GroupTreeView);
