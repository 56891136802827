import React, { useState, useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";

import { Segment, Dimmer, Loader } from "semantic-ui-react";
import { notification } from "antd"; // TODO: Currently there's no equivalent for this component in Semantic UI. Eventually a component can be created with a similar behaviour, or perhaps use an external notification library instead.
import {
  getGroupMailPropertiesForGroup,
  updateGroupMailPropertiesForGroup,
} from "../../../actions/api";
import MailAliases from "./MailAliases";
import MailPostingRestrictions from "./MailPostingRestrictions";
import MailDeliveryNotification from "./MailDeliveryNotification";
import MailArchive from "./MailArchive";
import EGroupWarning from "../EGroupWarning.js";
import { errorAction, types } from "@authzsvc/api-lib";

const GroupEmailProperties = ({ selectedGroup, canManageGroup }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [groupMailProperties, setGroupMailProperties] = useState({});
  const [isInitialized, setIsInitialized] = useState(false);

  const isReplicaGroup =
    selectedGroup.syncType === "SlaveWithPlaceholders" ||
    selectedGroup.syncType === "Slave";
  const canModifyGroup = canManageGroup && !isReplicaGroup;
  const {
    id,
    primaryMailAlias,
    mailAliases = [],
    allowAuthenticatedMessagesOnly = false,
    identitiesAllowedToPost = [],
    groupsAllowedToPost = [],
    deliveryFailureReport,
    enableArchive = false,
  } = groupMailProperties || {};

  const fetchGroupMailProperties = useCallback(() => {
    setLoading(true);

    dispatch(getGroupMailPropertiesForGroup(selectedGroup.id))
      .then((response) => {
        if (
          response?.type ===
          errorAction(types.GET_GROUP_MAIL_PROPERTIES_FOR_GROUP)
        ) {
          return Promise.reject(
            new Error("Failed to fetch group mail properties.")
          );
        }
        setGroupMailProperties(response.payload.data);
      })
      .catch(() => {
        notification.error({
          message: "Failed to fetch group mail properties.",
        });
      })
      .finally(() => {
        setIsInitialized(true);
        setLoading(false);
      });
  }, [dispatch, selectedGroup.id]);

  const updateGroupMailProperties = async (updates) => {
    const groupMailPropertiesWithUpdates = {
      ...groupMailProperties,
      ...updates,
    };

    setLoading(true);

    dispatch(
      updateGroupMailPropertiesForGroup(
        selectedGroup.id,
        groupMailPropertiesWithUpdates
      )
    )
      .then((actionResult) => {
        if (
          actionResult?.type ===
          errorAction(types.UPDATE_GROUP_MAIL_PROPERTIES_FOR_GROUP)
        ) {
          return Promise.reject(
            new Error("Failed to update group mail properties.")
          );
        }
        fetchGroupMailProperties();
      })
      .then(() => {
        notification.success({
          message: "Group Email Properties updated successfully!",
        });
      })
      .catch(() => {
        notification.error({
          message: "Failed to update group mail properties.",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchGroupMailProperties();
  }, [fetchGroupMailProperties]);

  return (
    <div style={{ position: "relative" }}>
      {isInitialized ? (
        <>
          <EGroupWarning
            selectedGroup={selectedGroup}
            canManageGroup
            showSyncMessage={false}
          />
          <MailAliases
            primaryMailAlias={primaryMailAlias}
            mailAliases={mailAliases}
            canModifyGroup={canModifyGroup}
            updateGroupMailProperties={updateGroupMailProperties}
          />
          <MailPostingRestrictions
            groupMailPropertiesId={id}
            identitiesAllowedToPost={identitiesAllowedToPost}
            groupsAllowedToPost={groupsAllowedToPost}
            selectedGroup={selectedGroup}
            allowAuthenticatedMessagesOnly={allowAuthenticatedMessagesOnly}
            canModifyGroup={canModifyGroup}
            updateGroupMailProperties={updateGroupMailProperties}
            fetchGroupMailProperties={fetchGroupMailProperties}
            setLoading={setLoading}
          />
          <MailDeliveryNotification
            deliveryFailureReport={deliveryFailureReport}
            canModifyGroup={canModifyGroup}
            updateGroupMailProperties={updateGroupMailProperties}
          />
          <MailArchive
            enableArchive={enableArchive}
            selectedGroup={selectedGroup}
            canModifyGroup={canModifyGroup}
            updateGroupMailProperties={updateGroupMailProperties}
          />{" "}
        </>
      ) : (
        <Segment color="blue" size="huge" padded="very">
          <Loader active />
        </Segment>
      )}

      {loading && isInitialized && (
        <Dimmer
          active
          inverted
          page
          style={{ position: "fixed", zIndex: 1000 }}
        >
          <Loader inverted>Loading</Loader>
        </Dimmer>
      )}
    </div>
  );
};

GroupEmailProperties.propTypes = {
  selectedGroup: PropTypes.object,
  canManageGroup: PropTypes.bool,
};

export default GroupEmailProperties;
