import { types as at, errorAction, successAction } from "@authzsvc/api-lib";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectGroupById,
  transferGroupOwner,
  updateGroup,
} from "../../actions/api";
import { adminRoleIdentifier } from "../../config";
import { isApiCallLoading, userTokenHasRole } from "../../selectors";

import { UnorderedListOutlined } from "@ant-design/icons";
import { notification } from "antd";
import { withRouter } from "react-router";
import { Header, Placeholder, Segment } from "semantic-ui-react";

import TooltipInfoIcon from "../common/TooltipInfoIcon";
import GroupEditForm from "./GroupEditForm";
import GroupScopesSelector from "./scopes/GroupScopesSelector";
import { showErrorNotification } from "../../util";
import EGroupWarning from "./EGroupWarning";
import { InternalStateWarning } from "./InternalStateWarning";

const PlaceholderLoading = ({ loading, children, small }) => {
  let placeholder = (
    <Placeholder fluid>
      <Placeholder.Line />
      <Placeholder.Line />
      <Placeholder.Line />
      <Placeholder.Line />
      <Placeholder.Line />
      <Placeholder.Line />
      <Placeholder.Line />
    </Placeholder>
  );

  if (small) {
    placeholder = (
      <Placeholder>
        <Placeholder.Line />
        <Placeholder.Line />
      </Placeholder>
    );
  }

  if (loading) {
    return placeholder;
  } else if (children) {
    return children;
  }
};

PlaceholderLoading.propTypes = {
  loading: PropTypes.bool.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  small: PropTypes.bool,
};

/**
 * Clones the group and it updates it with all the fields passed
 * @param {object} group
 * @param {object} updated
 */
const updateGroupObject = (group, updated) => {
  let newGroup = { ...group };
  Object.keys(updated).forEach((key) => {
    newGroup[key] = updated[key];
  });
  return newGroup;
};

const GroupDetails = ({ canManageGroup, isDisableByInternalState }) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const selectedGroup = useSelector((state) => state.groups.selectedGroup);
  const groupMemberships = useSelector((state) => state.groupMembership.direct);
  const globalLoading = useSelector(
    (state) =>
      isApiCallLoading(state, at.GET_GROUP_BY_ID) ||
      isApiCallLoading(state, at.GET_GROUPS_I_OWN)
  );
  const isAdmin = useSelector((state) =>
    userTokenHasRole(state, adminRoleIdentifier)
  );

  const handleUpdate = async (values) => {
    setLoading(true);
    const updatedGroup = updateGroupObject(selectedGroup, values);
    const result = await dispatch(updateGroup(updatedGroup.id, updatedGroup));

    if (result.type === errorAction(at.UPDATE_GROUP)) {
      showErrorNotification(result.payload.title, result.payload.data.message);
    }

    await dispatch(selectGroupById(updatedGroup.id));
    setLoading(false);
  };

  const handleSelectNewOwner = async (owner) => {
    if (!owner) return;
    setLoading(true);

    const response = await dispatch(
      transferGroupOwner(selectedGroup.id, owner.id)
    );

    if (response.type === successAction(at.TRANSFER_GROUP_OWNER)) {
      if (response.payload.data.requestorId) {
        notification.warning({
          message: "Approval required by new administrator",
          description:
            "The owner or administrators of the group have been notified.",
        });
      } else {
        notification.success({
          message: "Ownership has been updated successfully!",
        });
        await dispatch(selectGroupById(selectedGroup.id));
      }
    } else if (response.type === errorAction(at.TRANSFER_GROUP_OWNER)) {
      const title = response?.payload?.title || "Sorry, an error occurred";
      const description =
        response?.payload?.data?.message || "Please try again later";

      showErrorNotification(title, description);
    }

    setLoading(false);
  };

  const warningStates = ["ActionRequired", "Blocked", "Archived", "Deleted"];
  const isInternalWarning = warningStates.includes(selectedGroup.internalState);

  return (
    <>
      <EGroupWarning
        selectedGroup={selectedGroup}
        canManageGroup
        showSyncMessage={false}
      />
      {isInternalWarning && (
        <InternalStateWarning selectedGroup={selectedGroup} />
      )}

      <Segment color="blue">
        <Segment basic>
          <PlaceholderLoading loading={globalLoading}>
            <GroupEditForm
              groupMemberships={groupMemberships}
              dynamic={selectedGroup.dynamic || false}
              onSelectOwner={handleSelectNewOwner}
              group={selectedGroup}
              handleSubmit={handleUpdate}
              loading={loading || globalLoading}
              canManage={canManageGroup || isAdmin}
              isDisableByInternalState={isDisableByInternalState}
            />
          </PlaceholderLoading>
        </Segment>
      </Segment>
      {(canManageGroup || isAdmin) && (
        <Segment color="blue">
          <Header size="medium">
            <UnorderedListOutlined /> Group usage
            <TooltipInfoIcon title="The group will be shared with the systems and services selected." />
          </Header>
          {selectedGroup.id && (
            <GroupScopesSelector groupId={selectedGroup.id} />
          )}
        </Segment>
      )}
    </>
  );
};

export default withRouter(GroupDetails);
