import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Container, Segment, Header, Icon } from "semantic-ui-react";
import GroupCreationForm from "./GroupCreationForm";
import { createStaticGroup } from "../../actions/api";
import { groupsUserRoleIdentifier } from "../../config";
import { showWarningNotification } from "../../util";
import PropTypes from "prop-types";

const GroupCreate = ({ title, dynamic, icon }) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  const handleCreate = async (values) => {
    setLoading(true);
    const response = await dispatch(createStaticGroup(values));

    if (response?.payload?.data) {
      if (response.payload.request_id) {
        showWarningNotification(
          response.payload.message,
          "The prefix administrators have been informed"
        );
        history.push(`/requests`);
      } else {
        history.push(`/group/${response.payload.data.id}/details`);
      }
    } else {
      console.error(response);
      history.push(`/`);
    }

    setLoading(false);
  };

  return (
    <Container>
      {groupsUserRoleIdentifier === "groups_user" ? (
        <Segment color="blue">
          <Header size="medium">
            <Icon.Group>
              <Icon name={icon} />
              <Icon corner name="add" />
            </Icon.Group>{" "}
            {title}
          </Header>
          <GroupCreationForm
            isEdit={false}
            dynamic={dynamic}
            handleSubmit={handleCreate}
            loading={loading}
          />
        </Segment>
      ) : (
        <Segment placeholder>
          <Header icon>
            <Icon name="lock" />
            You don't have the access rights to create a group.
          </Header>
        </Segment>
      )}
    </Container>
  );
};

GroupCreate.propTypes = {
  title: PropTypes.string.isRequired,
  dynamic: PropTypes.bool.isRequired,
  icon: PropTypes.string.isRequired,
};

export default GroupCreate;
