import React, { useState } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import {
  Segment,
  Header,
  Button,
  Icon,
  Checkbox,
  Modal,
  List,
} from "semantic-ui-react";
import { notification } from "antd"; // TODO: Currently there's no equivalent for this component in Semantic UI. Eventually a component can be created with a similar behaviour, or perhaps use an external notification library instead.
import {
  getIdentityById,
  selectAdminGroupById,
  addGroupMemberIdentities,
  addGroupMailPropertiesIdentitiesAllowedToPost,
  deleteGroupMailPropertiesIdentitiesAllowedToPost,
  addGroupMailPropertiesGroupsAllowedToPost,
  deleteGroupMailPropertiesGroupsAllowedToPost,
} from "../../../actions/api";
import { ContentTable } from "@authzsvc/common-react-components";
import TooltipInfoIcon from "../../common/TooltipInfoIcon";
import AddMemberSearchModal from "../../common/AddMemberSearchModal";
import { errorAction, types } from "@authzsvc/api-lib";

const MailPostingRestrictions = ({
  groupMailPropertiesId,
  identitiesAllowedToPost,
  groupsAllowedToPost,
  selectedGroup,
  allowAuthenticatedMessagesOnly,
  canModifyGroup,
  updateGroupMailProperties,
  fetchGroupMailProperties,
  setLoading,
}) => {
  const dispatch = useDispatch();
  const [restrictSendersLocalCopy, setRestrictSendersLocalCopy] = useState({});
  const [customSendersLocalCopy, setCustomSendersLocalCopy] = useState({});
  const [selectedRows, setSelectedRows] = useState([]);
  const [isRemoving, setIsRemoving] = useState(false);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isRemoveModalOpen, setIsRemoveModalOpen] = useState(false);
  const [itemsToRemove, setItemsToRemove] = useState([]);

  const restrictSendersSwitchValue =
    groupsAllowedToPost?.length > 0 || identitiesAllowedToPost?.length > 0;
  const groupAdminCheckboxValue =
    identitiesAllowedToPost?.some(
      (identityAllowedToPost) =>
        identityAllowedToPost?.id === selectedGroup.ownerId
    ) ||
    groupsAllowedToPost?.some(
      (groupAllowedToPost) =>
        groupAllowedToPost?.id === selectedGroup.administratorsId
    );
  const groupMembersCheckboxValue = groupsAllowedToPost?.some(
    (groupAllowedToPost) => groupAllowedToPost?.id === selectedGroup.id
  );
  const groupMembersCheckboxState =
    selectedGroup.id === selectedGroup.administratorsId;
  const hasNonOwnerIdentity =
    identitiesAllowedToPost?.filter(
      (identity) => identity.id !== selectedGroup.ownerId
    ).length > 0;
  const hasNonAdminNonSelectedGroup =
    groupsAllowedToPost?.filter(
      (group) =>
        group.id !== selectedGroup.id &&
        group.id !== selectedGroup.administratorsId
    ).length > 0;
  const customSendersCheckboxValue =
    restrictSendersSwitchValue &&
    (hasNonOwnerIdentity || hasNonAdminNonSelectedGroup || isAddModalOpen);
  const hasSelections = selectedRows.length > 0;
  const rowSelection = {
    selectedRowKeys: selectedRows,
    onChange: (selectedRowKeys) => {
      setSelectedRows(selectedRowKeys);
    },
  };
  const tableColumns = [
    {
      title: "Identifier",
      dataIndex: "displayName",
      render: (text, record) => {
        if (record.displayName && record.upn) {
          return `${record.displayName} (${record.upn})`;
        } else if (record.groupIdentifier) {
          return record.groupIdentifier;
        }
        return text;
      },
    },
    {
      title: "Type",
      dataIndex: "type",
      render: (text, record) => {
        if (record.displayName && record.upn) {
          return record.type;
        } else if (record.groupIdentifier) {
          return "Group";
        }
        return text;
      },
    },
  ];
  const dataSource = [
    ...identitiesAllowedToPost
      ?.filter((identity) => identity.id !== selectedGroup.ownerId)
      .map((identity) => ({
        key: identity.id,
        displayName: `${identity.displayName} (${identity.upn})`,
        type: identity.type,
      })),
    ...groupsAllowedToPost
      ?.filter(
        (group) =>
          group.id !== selectedGroup.id &&
          group.id !== selectedGroup.administratorsId
      )
      .map((group) => ({
        key: group.id,
        displayName: group.groupIdentifier,
        type: "Group",
      })),
  ];

  const handleRestrictAuthenticatedSendersSwitchChange = (_, data) => {
    updateGroupMailProperties({
      allowAuthenticatedMessagesOnly: data.checked,
    });
  };

  const dispatchCustomSendersAdd = async ({ identities = [], groups = [] }) => {
    const dispatchActions = [];
    if (identities.length) {
      dispatchActions.push(
        dispatch(
          addGroupMailPropertiesIdentitiesAllowedToPost(
            groupMailPropertiesId,
            identities
          )
        )
      );
    }
    if (groups.length) {
      dispatchActions.push(
        dispatch(
          addGroupMailPropertiesGroupsAllowedToPost(
            groupMailPropertiesId,
            groups
          )
        )
      );
    }
    if (dispatchActions.length) {
      setLoading(true);

      Promise.all(dispatchActions)
        .then((response) => {
          const isErrorAction =
            response?.[0].type ===
              errorAction(
                types.ADD_GROUP_MAIL_PROPERTIES_IDENTITIES_ALLOWED_TO_POST
              ) ||
            response?.[0].type ===
              errorAction(
                types.ADD_GROUP_MAIL_PROPERTIES_GROUPS_ALLOWED_TO_POST
              );

          if (isErrorAction) {
            return Promise.reject(
              new Error("Failed to update custom senders.")
            );
          }

          fetchGroupMailProperties();
          notification.success({
            message: "Group Email Properties updated successfully!",
          });
        })
        .catch(() => {
          notification.error({ message: "Failed to update custom senders." });
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      notification.info({ message: "No changes made to custom senders." });
    }
  };

  const dispatchCustomSendersRemove = async ({
    identities = [],
    groups = [],
  }) => {
    const dispatchActions = [];
    if (identities.length) {
      dispatchActions.push(
        dispatch(
          deleteGroupMailPropertiesIdentitiesAllowedToPost(
            groupMailPropertiesId,
            identities
          )
        )
      );
    }
    if (groups.length) {
      dispatchActions.push(
        dispatch(
          deleteGroupMailPropertiesGroupsAllowedToPost(
            groupMailPropertiesId,
            groups
          )
        )
      );
    }
    if (dispatchActions.length) {
      setLoading(true);

      Promise.all(dispatchActions)
        .then((response) => {
          const isErrorAction =
            response?.[0].type ===
              errorAction(
                types.DELETE_GROUP_MAIL_PROPERTIES_IDENTITIES_ALLOWED_TO_POST
              ) ||
            response?.[0].type ===
              errorAction(
                types.DELETE_GROUP_MAIL_PROPERTIES_GROUPS_ALLOWED_TO_POST
              );

          if (isErrorAction) {
            return Promise.reject(
              new Error("Failed to remove custom senders.")
            );
          }

          fetchGroupMailProperties();

          notification.success({
            message: "Group Email Properties updated successfully!",
          });
        })
        .catch(() => {
          notification.error({ message: "Failed to remove custom senders." });
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      notification.info({ message: "No changes made to custom senders." });
    }
  };

  const updateCustomSendersWithGroupOwnersAdmins = async () => {
    const fetchPromises = [];
    if (selectedGroup.ownerId) {
      fetchPromises.push(dispatch(getIdentityById(selectedGroup.ownerId)));
    }
    if (selectedGroup.administratorsId) {
      fetchPromises.push(
        dispatch(selectAdminGroupById(selectedGroup.administratorsId))
      );
    }
    setLoading(true);

    Promise.all(fetchPromises)
      .then(([identityResponse, groupResponse]) => {
        const adminIdentity = identityResponse.payload.data.id
          ? [identityResponse.payload.data.id]
          : [];
        const ownerGroup = groupResponse?.payload.data.groupIdentifier
          ? [groupResponse.payload.data.groupIdentifier]
          : [];

        dispatchCustomSendersAdd({
          identities: adminIdentity,
          groups: ownerGroup,
        });
      })
      .catch(() => {
        notification.error({
          message: "Failed to fetch group owner or administrator details.",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleRestrictSendersSwitchChange = (_, data) => {
    if (data.checked) {
      if (Object.keys(restrictSendersLocalCopy).length) {
        const identitiesToUpdate = (
          restrictSendersLocalCopy.identitiesAllowedToPost || []
        ).map((identity) => identity.id);
        const groupsToUpdate = (
          restrictSendersLocalCopy.groupsAllowedToPost || []
        ).map((group) => group.groupIdentifier);
        dispatchCustomSendersAdd({
          identities: identitiesToUpdate,
          groups: groupsToUpdate,
        });
      } else {
        updateCustomSendersWithGroupOwnersAdmins();
      }
    } else {
      setRestrictSendersLocalCopy({
        identitiesAllowedToPost: [...identitiesAllowedToPost],
        groupsAllowedToPost: [...groupsAllowedToPost],
      });
      dispatchCustomSendersRemove({
        identities: [...identitiesAllowedToPost].map((identity) => identity.id),
        groups: [...groupsAllowedToPost].map((group) => group.id),
      });
    }
  };

  const handleGroupMembersCheckboxChange = (_, data) => {
    if (data.checked) {
      dispatchCustomSendersAdd({
        groups: [selectedGroup.groupIdentifier],
      });
    } else {
      if (selectedGroup.id !== selectedGroup.administratorsId) {
        dispatchCustomSendersRemove({ groups: [selectedGroup.id] });
      } else {
        notification.error({
          message:
            "Group Members cannot be unchecked if the group is its own administrator.",
        });
      }
    }
  };

  const getRowKey = (customSender) => {
    if (customSender.type === "Group") {
      return "group-" + customSender.key;
    } else {
      return "identity-" + customSender.key;
    }
  };

  const handleAddModalOpen = () => {
    setIsAddModalOpen(true);
  };

  const handleAddModalClose = () => {
    setIsAddModalOpen(false);
  };

  const handleCustomSendersCheckboxChange = (_, data) => {
    if (data.checked) {
      if (Object.keys(customSendersLocalCopy).length) {
        const identitiesToAdd = [
          ...customSendersLocalCopy.identitiesAllowedToPost,
        ].map((identity) => identity.id);
        const groupsToAdd = [...customSendersLocalCopy.groupsAllowedToPost].map(
          (group) => group.groupIdentifier
        );
        dispatchCustomSendersAdd({
          identities: identitiesToAdd,
          groups: groupsToAdd,
        });
      } else {
        handleAddModalOpen();
      }
    } else {
      const identitiesToRemove = identitiesAllowedToPost?.filter(
        (identity) => identity.id !== selectedGroup.ownerId
      );
      const groupsToRemove = groupsAllowedToPost?.filter((group) => {
        const isNotAdminGroup = group.id !== selectedGroup.administratorsId;
        const isNotCheckedSelectedGroup = groupMembersCheckboxValue
          ? group.id !== selectedGroup.id
          : true;
        return isNotAdminGroup && isNotCheckedSelectedGroup;
      });
      setCustomSendersLocalCopy({
        identitiesAllowedToPost: identitiesToRemove,
        groupsAllowedToPost: groupsToRemove,
      });
      dispatchCustomSendersRemove({
        identities: identitiesToRemove.map((identity) => identity.id),
        groups: groupsToRemove.map((group) => group.id),
      });
    }
  };

  const handleAddMember = (addedMember = {}) => {
    const { id, upn, groupIdentifier } = addedMember;
    let identitiesToUpdate = [],
      groupsToUpdate = [];
    if (upn && id) {
      identitiesToUpdate.push(id);
    } else if (groupIdentifier) {
      groupsToUpdate.push(groupIdentifier);
    }
    dispatchCustomSendersAdd({
      identities: identitiesToUpdate,
      groups: groupsToUpdate,
    });
  };

  const handleCreateByEmail = async (email) => {
    const groupId = selectedGroup.id;
    const identityIds = [{ id: email }];
    try {
      setLoading(true);
      const response = await dispatch(
        addGroupMemberIdentities(groupId, identityIds)
      );
      await dispatchCustomSendersAdd({
        identities: [response.payload.data.identityId],
      });
    } catch (error) {
      notification.error({
        message: "Failed to add new external email to custom senders.",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleRemove = () => {
    const filteredIdentities = identitiesAllowedToPost
      ?.filter((identity) => selectedRows.includes(`identity-${identity.id}`))
      .map((identity) => `${identity.displayName} (${identity.upn})`);
    const filteredGroups = groupsAllowedToPost
      ?.filter((group) => selectedRows.includes(`group-${group.id}`))
      .map((group) => group.displayName);
    setItemsToRemove([...filteredIdentities, ...filteredGroups]);
    setIsRemoveModalOpen(true);
  };

  const handleRemoveModalOk = () => {
    handleCustomSendersRemove();
    setIsRemoveModalOpen(false);
  };

  const handleRemoveModalCancel = () => {
    setIsRemoveModalOpen(false);
  };

  const handleCustomSendersRemove = async () => {
    setIsRemoving(true);
    const filterAndCleanItems = (items, prefix) => {
      return items
        ?.filter((item) => selectedRows.includes(`${prefix}-${item.id}`))
        .map((item) => item.id.replace(`${prefix}-`, ""));
    };
    const identitiesToRemove = filterAndCleanItems(
      identitiesAllowedToPost,
      "identity"
    );
    const groupsToRemove = filterAndCleanItems(groupsAllowedToPost, "group");
    dispatchCustomSendersRemove({
      identities: identitiesToRemove,
      groups: groupsToRemove,
    });
    setSelectedRows([]);
    setIsRemoving(false);
  };

  return (
    <>
      <Segment color="blue">
        <Header size="medium">Email Posting Restrictions</Header>
        <Segment basic>
          <Checkbox
            toggle
            label={
              <label>
                Restrict to authenticated senders{" "}
                <TooltipInfoIcon
                  title="In addition to the posting restrictions below, only accept mails sent from a CERN mailbox (Outlook, Thunderbird etc.), and automatic messages from CERN services. Messages coming from the Internet will be refused."
                  size={"small"}
                />
              </label>
            }
            disabled={!canModifyGroup}
            onChange={handleRestrictAuthenticatedSendersSwitchChange}
            checked={allowAuthenticatedMessagesOnly}
          />
          <br />
          <br />
          <Checkbox
            toggle
            label="Restrict who can send emails to this group"
            disabled={!canModifyGroup}
            onChange={handleRestrictSendersSwitchChange}
            checked={restrictSendersSwitchValue}
          />
          {restrictSendersSwitchValue && (
            <>
              <Segment
                basic
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "8px",
                  marginLeft: "16px",
                }}
              >
                <Checkbox
                  label="Owners/Admins of the group"
                  checked={groupAdminCheckboxValue}
                  disabled
                />
                <Checkbox
                  label={
                    <label>
                      Group Members{" "}
                      {groupMembersCheckboxState && (
                        <TooltipInfoIcon
                          title="Group Members cannot be unchecked if the group is its own administrator."
                          size={"small"}
                        />
                      )}
                    </label>
                  }
                  onChange={handleGroupMembersCheckboxChange}
                  checked={groupMembersCheckboxValue}
                  disabled={groupMembersCheckboxState || !canModifyGroup}
                />
                <Checkbox
                  label="Custom senders"
                  onChange={handleCustomSendersCheckboxChange}
                  checked={customSendersCheckboxValue}
                  disabled={!canModifyGroup}
                />
              </Segment>
              {customSendersCheckboxValue && (
                <>
                  <Segment basic>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "end",
                        marginBottom: "24px",
                      }}
                    >
                      <AddMemberSearchModal
                        title="Add Custom Senders"
                        searchValue=""
                        isReplicaGroup={!canModifyGroup}
                        onSelect={handleAddMember}
                        onCreateByEmail={handleCreateByEmail}
                        isModalOpen={isAddModalOpen}
                        onModalClose={handleAddModalClose}
                      />
                    </div>
                    <ContentTable
                      rowKey={getRowKey}
                      pagination={{
                        position: ["none", "none"],
                      }}
                      columns={tableColumns}
                      dataSource={dataSource}
                      rowSelection={rowSelection}
                    />
                  </Segment>
                  <Segment basic>
                    <Button
                      id="customSendersRemoveSelectionButton"
                      loading={isRemoving}
                      disabled={!hasSelections || !canModifyGroup}
                      color={!hasSelections || !canModifyGroup ? "grey" : "red"}
                      onClick={() => handleRemove("customSenders")}
                      icon="trash alternate"
                    />
                  </Segment>
                </>
              )}
            </>
          )}
        </Segment>
      </Segment>
      <Modal
        closeIcon
        open={isRemoveModalOpen}
        onClose={handleRemoveModalCancel}
        size="small"
        centered
      >
        <Modal.Header>
          <span>
            <Icon name="trash" color="red" style={{ marginRight: "1.5rem" }} />
            Confirm Deletion
          </span>
        </Modal.Header>
        <Modal.Content>
          <p>Are you sure you want to remove the following custom senders?</p>
          <List
            bulleted
            items={itemsToRemove.map((item, index) => ({
              key: index,
              content: item,
            }))}
          />
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={handleRemoveModalCancel}>No</Button>
          <Button negative onClick={handleRemoveModalOk}>
            Yes
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  );
};

MailPostingRestrictions.propTypes = {
  groupMailPropertiesId: PropTypes.string,
  identitiesAllowedToPost: PropTypes.arrayOf(PropTypes.object),
  groupsAllowedToPost: PropTypes.arrayOf(PropTypes.object),
  selectedGroup: PropTypes.object,
  allowAuthenticatedMessagesOnly: PropTypes.bool,
  canModifyGroup: PropTypes.bool,
  updateGroupMailProperties: PropTypes.func,
  fetchGroupMailProperties: PropTypes.func,
  setLoading: PropTypes.func,
};

export default MailPostingRestrictions;
